import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import MenuNavbar from "./MenuNavbar";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import Loading from "../../Components/Loading/Loading";

function FailedMPSEATransaction() {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const navigate = useNavigate();
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;
  const state = useSelector((state) => state.changeTheState);
  const UserEmail = state?.user ? state?.user?.emailId?.split("@")[0] : "N/A";
  const [loading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [ReceiptNo, setReceiptNo] = useState(0);

  //   const excelDateToJSDate = (serial) => {
  //     const utc_days = Math.floor(serial - 25569);
  //     const utc_value = utc_days * 86400;
  //     const date_info = new Date(utc_value * 1000);
  //     return date_info.toISOString().split("T")[0];
  //   };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      // Assuming first sheet is the relevant one
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      // Parse data for table (ignoring header row)
      const parsedData = jsonData.slice(1).map((row) => ({
        clientcode: row[0], // Assuming 'Client Code' is in the first column (index 0)
        mpesaRefNo: row[1], // Assuming 'MPESA Reference' is in the second column (index 1)
        // paymentDate: excelDateToJSDate(row[2]), // Convert Excel serial to proper date
        paymentAmount: row[2], // Assuming 'Payment Amount' is in the fourth column
      }));

      setTableData(parsedData);
    };

    reader.readAsArrayBuffer(file);
  };

  const handleSave = async () => {
    const invalidRows = [];

    // Validate all rows first
    tableData.forEach((row, index) => {
      const { clientcode, mpesaRefNo, paymentAmount } = row;

      // Validate each field and log invalid rows
      if (typeof clientcode !== "string" || clientcode.trim() === "") {
        invalidRows.push(`Row ${index + 1}: Invalid Client Code`);
      }

      if (typeof mpesaRefNo !== "string" || mpesaRefNo.trim() === "") {
        invalidRows.push(`Row ${index + 1}: Invalid MPESA Reference No`);
      }

      if (isNaN(Number(paymentAmount)) || Number(paymentAmount) <= 0) {
        invalidRows.push(`Row ${index + 1}: Invalid Payment Amount`);
      }
    });

    // If there are invalid rows, show an alert and stop the process
    if (invalidRows.length > 0) {
      alert(`The following rows have invalid data:\n${invalidRows.join("\n")}`);
      return; // Stop here if any invalid rows exist
    }

    // Proceed with saving only if all rows are valid
    try {
      setIsLoading(true); // Show loading indicator

      const requests = tableData.map((row) => {
        const { clientcode, mpesaRefNo, paymentAmount } = row;

        return axios.post(
          `https://api.tech23.net/paymentconfirmation/confirmation`,
          {
            TransactionType: "Pay Bill",
            BusinessShortCode: 4103477,
            TransAmount: paymentAmount,
            BillRefNumber: clientcode,
            TransID: mpesaRefNo,
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        );
      });

      // Send all requests in parallel using Promise.all
      const responses = await Promise.all(requests);
      console.log("Responses from API:", responses);

      alert("Payments saved and receipts updated successfully!");
    } catch (error) {
      console.error("Error saving payment or updating receipt:", error);
      alert(
        "An error occurred while saving some payments. Please check the console for details."
      );
    } finally {
      setIsLoading(false); // Hide loading indicator
    }
  };

  if (loading) {
    <Loading />;
    return;
  }
  return (
    <div>
      <div className="toHIde__when__Print">
        <MenuNavbar />
      </div>
      <header className="header__of__main lg:flex justify-between px-3 items-center">
        <h1 className="header__of__page">
          {menu ? menu : "FAILED MPESA TRANSACTIONS - PUNCH TO PUSH"}
        </h1>
      </header>

      <div className="ml-8 mb-2 lg:flex justify-between gap-2 mt-2">
        <div className="lg:flex justify-between">
          <p className="text-xl font-bold">Upload Excel : </p>
          <input type="file" onChange={handleFileUpload} />
        </div>
        <div className="savebutton mr-2">
          <button className="button" onClick={handleSave}>
            Save
          </button>
        </div>
      </div>
      <section className="section__newgarage ml-8 for__table__width__full">
        {tableData.length > 0 && (
          <table className="table_1  table__size__reducer ">
            <thead>
              <tr style={{ fontWeight: "400" }}>
                <td>Client Code</td>
                <td>MPESA Reference No</td>

                <td>Payment Amount</td>
              </tr>
            </thead>
            <tbody>
              {tableData.map((row, index) => (
                <tr key={index} className="table-row">
                  <td>{row.clientcode}</td>
                  <td>{row.mpesaRefNo}</td>
                  {/* <td>{row.paymentDate}</td> */}
                  <td>{row.paymentAmount}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </section>
    </div>
  );
}

export default FailedMPSEATransaction;
