import React from "react";
import axios from "axios";
import { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { BiSolidEditAlt } from "react-icons/bi";
import MenuNavbar from "./MenuNavbar";
import { useSelector } from "react-redux/es/hooks/useSelector";
import Select from "react-select";
import "../PropertyManagementCss/elctricpage.css";
import moment from "moment";
import { FaSearch } from "react-icons/fa";
import Loading from "../../Components/Loading/Loading";
import makeAnimated from "react-select/animated";
import { MultiSelect } from "react-multi-select-component";

function InspectionDetails() {
  const navigate = useNavigate();

  const location = useLocation();
  const menu = location.state ? location.state.menu : null;

  console.log(menu);
  const { inspectionData } = location.state || {};
  console.log(inspectionData);
  const [isLoading, setIsloading] = useState(true);
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const [InspectionNo, SetInspectionNo] = useState([]);
  const [InspectionLeaseNumber, setInspectionLeaseNumber] = useState([]);
  const [leasenumber, setleasenumber] = useState([]);
  const [dateValue, setDateValue] = useState();
  const [Carriedoutby, setCarriedBy] = useState([]);
  const [clientName, setClientName] = useState([]);
  const [HeaderType, setHeaderType] = useState("ENTRY");
  const [narration, setNarration] = useState("");
  const [CarriedByvalue, setCarriedByValue] = useState("");
  const [unitNo, setUnitno] = useState("");
  const [EditableInspctionNo, setEditableInspctionNo] = useState("");
  const [editableLeaseNo, seteditableLeaseNo] = useState("");
  const [editProcessedBy, seteditProcessedBy] = useState("");
  const [editInspectionDate, seteditInspectionDate] = useState("");
  const [selectedValues, setSelectedValues] = useState([]);
  const [InspectionStatus, setInspectionStatus] = useState("");
  const [Inspectionleaseno, setInspectionleaseno] = useState("");
  const [Meterreading, setMeterReading] = useState(0);
  const [search, setSearch] = useState("");
  const [InspectItems, setInspectItems] = useState([]);
  const selectedClientNameRef = useRef();
  const unitNoRef = useRef();
  // const [Moveinoption, setMoveinoption] = useState([]);
  const [FetchInspectionHeaderDetails, setFetchInspectionHeaderDetails] =
    useState([]);
  const userState = useSelector((state) => state.changeTheState.user);
  console.log(InspectionNo?.NEXTNO);
  const [inspectionItem, setinspectionItem] = useState([]);

  const [InspectionArea, setInspectionArea] = useState([]);

  const [moveInDetails, setMoveInDetails] = useState([]);
  const [OptionInspectionStatus, setOptionInspectionStatus] = useState("");
  const [narrationDetails, setNarrationDetails] = useState([]);

  const [InspectionDetailstomap, setInspectionDetailstomap] = useState([]);
  const BASEURL = process.env.REACT_APP_BASEURL;
  console.log(dateValue);

  console.log(userState?.userCode);
  const [initialMoveInValues, setInitialMoveInValues] = useState([]);
  const initialMoveInOptions = new Array(InspectItems.length).fill("");
  const [Moveinoption, setMoveinoption] = useState(initialMoveInOptions);
  const [subUnitNumber, setsubUnitNumber] = useState("");
  const [PropertyIdpost, setPropertyId] = useState("");
  const [PropertyName, setPropertyName] = useState([]);
  const [imagearray, setimagearray] = useState([]);
  const [inspectionDocument, setInspectionDocument] = useState(null);
  const [inspectionDocumentNumber, setInspectionDocumentNumber] =
    useState(null);
  const [inspectionDocumentNarration, setInspectionDocumentNarration] =
    useState(null);
  const [preview, setPreview] = useState(null);
  const handleFileChange = (event) => {
    const file = event.target.files[0]; // Get the selected file
    setInspectionDocument(file);
    if (file && file.type.startsWith("image/")) {
      const previewUrl = URL.createObjectURL(file);
      setPreview(previewUrl);
    } else {
      setPreview(null); // Reset preview if it's not an image
    } // Store the file in the state variable
  };

  function fetchInitialData() {
    setIsloading(true);
    axios
      .get(`${BASEURL}/getpropertyinspectionid`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        console.log(response.data[0]);
        SetInspectionNo(response.data[0]);
        setIsloading(false);
        // console.log(response.data[0]?.NEXTNO);
      })
      .catch((err) => {
        alert("Server error");
      });

    axios
      .get(`${BASEURL}/employeelist`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        console.log(response.data);
        setCarriedBy(response.data);
        // console.log(response.data[0]?.NEXTNO);
      })
      .catch((err) => {
        alert("Server error");
      });
    axios
      .get(`${BASEURL}/ListOfPropertyunitleases`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        console.log(response.data);
        const filteredData = response.data.filter(
          (item) => item.EXPIREDLEASE === false
        );

        // Set the filtered data to clientName or handle it as needed
        setClientName(filteredData);
        // setsubUnitNumber(filteredData?.SUBUNITNO);

        console.log(filteredData);
      })
      .catch((err) => {
        alert("Server error");
      });

    axios
      .get(`${BASEURL}/getinspectionheaders`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        console.log(response.data);
        setFetchInspectionHeaderDetails(response.data);
        // console.log(response.data[0]?.NEXTNO);
      })
      .catch((err) => {
        console.log("Server error");
      });

    axios
      .get(`${BASEURL}/getinspectionlist`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        console.log(response.data);
        // setFetchInspectionHeaderDetails(response.data);
        // console.log(response.data[0]?.NEXTNO);
        setinspectionItem(response.data);
      })
      .catch((err) => {
        alert("Server error");
      });

    axios
      .get(`${BASEURL}/getinspectionsdetails`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        console.log(response.data);
        setInspectionDetailstomap(response.data);
      })
      .catch((err) => {
        alert("Server error");
      });
    axios
      .get(`${BASEURL}/getnewinspectionDocumentno`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        console.log(response.data[0]?.DOCUMENTNO);
        setInspectionDocumentNumber(response.data[0]?.DOCUMENTNO);
      })
      .catch((err) => {
        // alert("Server error");
      });
  }
  function edifunction() {
    if (inspectionData && inspectionData.INSPECTIONNO !== undefined) {
      console.log(inspectionData.INSPECTIONNO);
      axios
        .post(
          `${BASEURL}/getpropertyinspectiondetails`,
          {
            INSPECTIONNO: inspectionData.INSPECTIONNO,
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          console.log(response);
          setInspectItems(response.data);
          // setOptionInspectionStatus(response.data[0].INSPECTIONSTATUS);
          if (response.data && response.data.length > 0) {
            setMoveinoption(response.data.map((item) => item.INSPECTIONSTATUS));
          } else {
            // Handle the case where response.data is empty
            setMoveinoption([]);
          }
        });

      console.log(inspectionData?.LEASENUMBER);
      setleasenumber(inspectionData.LEASENUMBER);
      console.log(inspectionData?.LEASENUMBER);
      setNarration(inspectionData?.NARRATION);
      console.log(inspectionData?.NARRATION);
      setClientCode(inspectionData?.CLIENTCODE);
      setsubUnitNumber(inspectionData?.SUBUNITNO);
      setPropertyName(inspectionData?.PROJECTNAME);
      console.log(inspectionData?.PROJECTID);
      setPropertyId(inspectionData?.PROJECTID);
      console.log(inspectionData?.CLIENTCODE);
      // console.log(item.CLIENT)
      console.log(inspectionData);
      setCarriedByValue(inspectionData?.PROCESSEDBY);
      seteditInspectionDate(inspectionData?.INSPECTIONDATE);
      setEditableInspctionNo(inspectionData?.INSPECTIONNO);
      setInspectionStatus(inspectionData?.INSPECTIONSTATUS);
      setMoveinoption(inspectionData?.INSPECTIONSTATUS);
      setMeterReading(inspectionData?.METERREADING);
      console.log(inspectionData?.METERREADING);

      axios
        .get(
          `${BASEURL}/getSavedInspectionDocuments?INSPECTIONNO=${inspectionData.INSPECTIONNO}`,
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          setimagearray(response.data);
          console.log(response.data[0]?.NARRATION);
          setInspectionDocument(response.data[0].DOCUMENTATTACHED);
          setInspectionDocumentNumber(response.data[0]?.DOCUMENTNO);
          setInspectionDocumentNarration(response.data[0]?.NARRATION);
          setPreview(response.data[0].DOCUMENTATTACHED);
        })
        .catch((err) => {
          // alert("Server error");
          console.log(err);
        });
    }
    axios
      .post(
        `${BASEURL}/getpropertyinspectionheader`,
        {
          INSPECTIONNO: inspectionData?.INSPECTIONNO,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        console.log(response.data[0].METERREADING);
        setMeterReading(response.data[0].METERREADING);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (inspectionData) {
      edifunction();
    }
  }, [inspectionData]);
  const uniqueInspectionAreas = [
    ...new Set(inspectionItem.map((item) => item.INSPECTIONAREAS)),
  ];
  console.log(uniqueInspectionAreas);
  useEffect(() => {
    scrollToSection();
    fetchInitialData();
    setInitialMoveInValues(null);
    setIsloading(false);
    const currentDate = new Date().toISOString().split("T")[0];
    setDateValue(currentDate);
  }, [InspectionNo.NEXTNO]);

  const [selectedValue, setSelectedValue] = useState(null);

  const handleSelectChange = (selectedOption) => {
    setSelectedValues((prevValues) => [...prevValues, selectedOption.value]);

    axios
      .post(
        `${BASEURL}/filterinspectionbyareas`,
        {
          INSPECTIONAREAS: selectedOption.value,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        // Assuming response.data is an array of items, adjust accordingly
        setInspectItems((prevInspectItems) => {
          const updatedItems = response?.data.map((item) => ({
            INSPECTIONITEM: item.INSPECTIONITEM,
            INSPECTIONAREA: selectedOption.value,
            INSPECTIONSTATUS: item.INSPECTIONSTATUS || "",
            ITEMNARRATION: item.ITEMNARRATION || "",
          }));

          // Combine the previous items with the updated ones
          return [...prevInspectItems, ...updatedItems];
        });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    // if (inspectionData) {
    //   console.log(inspectionData.INSPECTIONNO);
    //   axios
    //     .post(
    //       `${BASEURL}/getpropertyinspectiondetails`,
    //       {
    //         INSPECTIONNO: inspectionData.INSPECTIONNO,
    //       },
    //       {
    //         headers: {
    //           "auth-token": authToken,
    //         },
    //       }
    //     )
    //     .then((response) => {
    //       console.log(response);
    //       setInspectItems(response.data);
    //     });

    //   // console.log(item.LEASENUMBER);
    //   // setleasenumber(item.LEASENUMBER);
    //   // console.log(item.LEASENUMBER);
    //   // setNarration(item.NARRATION);
    //   // setClientCode(item.CLIENTCODE);
    //   // console.log(item.CLIENTCODE);
    //   // // console.log(item.CLIENT)
    //   // setCarriedByValue(item.PROCESSEDBY);
    //   // seteditInspectionDate(item.INSPECTIONDATE);
    //   // setEditableInspctionNo(item.INSPECTIONNO);
    // }
  };

  console.log(Inspectionleaseno);
  console.log(leasenumber);
  async function Save() {
    let hasError = false; // Flag to track if any errors occurred

    try {
      // Check if either EditableInspctionNo or InspectionNo.NEXTNO is present
      if (!(EditableInspctionNo || InspectionNo.NEXTNO)) {
        alert("Please provide InspectionNo");
        hasError = true;
        return;
      }
      if (!leasenumber) {
        alert("Please provide leasenumber");
        hasError = true;
        return;
      }

      // Check if other mandatory values are present
      if (!HeaderType || !narration || !CarriedByvalue) {
        alert("Please fill in all mandatory details");
        hasError = true;
        return;
      }
      if (!Meterreading) {
        alert("Please Supply Meter Reading");
        hasError = true;
        return;
      }

      // Prepare header payload
      const headerTypeValue = HeaderType;
      const inspectionNoValue = EditableInspctionNo || InspectionNo.NEXTNO;
      const leaseNumberValue = leasenumber || Inspectionleaseno;
      const narrationValue = narration;
      const dateValueValue = dateValue || editInspectionDate;
      const carriedByValue = CarriedByvalue;

      const headerPayload = {
        INSPECTIONTYPE: headerTypeValue,
        INSPECTIONNO: inspectionNoValue,
        INSPECTIONLEASENUMBER: leaseNumberValue,
        INSPECTIONCLIENT: clientCode,
        INSPECTIONNARRATION: narrationValue,
        INSPECTIONDATE: dateValueValue,
        INSPECTIONCARRIEDOUTBY: carriedByValue,
        EXITDATE: "",
        EXITCARRIEDBY: "",
        INSPECTIONEXITNARRATION: "",
        INSPECTIONEXITCARRIEDDATE: "",
        DAMAGENARRATION: "",
        DAMAGEAMOUNT: 0,
        DAMAGETOBEPAIDBYTENANT: 0,
        PROPERTYID: PropertyIdpost,
        SUBUNITNO: subUnitNumber,
        METERREADING: Meterreading,
      };

      console.log("headerPayload:", headerPayload);

      // Save header data
      const headerResponse = await axios.post(
        `${BASEURL}/savepropertyinspectionheader`,
        headerPayload,
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      );

      console.log(headerResponse.data);
      console.log(InspectItems);

      if (headerResponse.data) {
        // Prepare details payload
        const detailsPayload = InspectItems.map((row, index) => ({
          INSPECTIONTYPE: "ENTRY",
          INSPECTIONNO: inspectionNoValue,
          INSPECTIONAREA: row.INSPECTIONAREA,
          INSPECTIONITEM: row.INSPECTIONITEM,
          INSPECTIONITEMSTATUS:
            Moveinoption[index] !== "OTHER"
              ? Moveinoption[index]
              : Moveinoption[index + "Input"] || row.INSPECTIONSTATUS,
          INSPECTIONITEMNARRATION:
            narrationDetails[index] !== undefined
              ? narrationDetails[index]
              : row.ITEMNARRATION,
          EXITSTATUSNARRATION: "",
          EXITSTATUS: "",
        }));

        console.log(detailsPayload);

        // Check if any details payload is missing status
        if (
          detailsPayload.some(
            (payload) => payload.INSPECTIONITEMSTATUS === undefined
          )
        ) {
          alert("Please select the status");
          hasError = true;
        } else {
          // Save details data
          for (const payload of detailsPayload) {
            await axios.post(
              `${BASEURL}/savepropertyinspectiondetails`,
              payload,
              {
                headers: {
                  "auth-token": authToken,
                  "session-token": sessiontoken,
                },
              }
            );
          }

          if (detailsPayload.length > 0) {
            console.log("All inspections saved successfully");
          } else {
            alert("No inspections to save");
            hasError = true;
          }
        }
      }

      // Prepare and upload document data
      const inspectionNoValues = EditableInspctionNo || InspectionNo.NEXTNO;
      console.log(
        inspectionNoValues,
        inspectionDocument,
        userState?.userCode,
        inspectionDocumentNarration
      );
      const formData = new FormData();

      formData.append("INSPECTIONNO", inspectionNoValues);
      formData.append("INSPECTIONDOCUMENTNUMBER", inspectionDocumentNumber);
      formData.append(
        "INSPECTIONDOCUMENTATTACHEDNARRATION",
        inspectionDocumentNarration
      );
      formData.append("INSPECTIONDOCUMENTATTACHEDBY", userState?.userCode);
      formData.append("imageValue", inspectionDocument);

      await axios.post(`${BASEURL}/uploadleaseinspectionDocuments`, formData, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
          "Content-Type": "multipart/form-data",
        },
      });

      console.log("Document uploaded successfully");

      // Display a consolidated alert message
      if (!hasError) {
        alert("Inspection data saved successfully");
      }
    } catch (error) {
      console.error("Error saving inspections:", error);
      alert("Error saving inspections. Please try again.");
    }
  }

  const scrollToSection = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const [selectedClientName, setSelectedClientName] = useState("");
  const [clientCode, setClientCode] = useState("");

  const handleClientNameChange = (selectedOption) => {
    setSelectedClientName(selectedOption.value);

    const selectedClient = clientName.find(
      (item) => item.CLIENTNAME === selectedOption.value
    );

    setClientCode(selectedClient ? selectedClient.CLIENTCODE : "");
    setsubUnitNumber(selectedClient ? selectedClient.SUBUNITNO : "");
    setleasenumber(selectedClient ? selectedClient.LEASENUMBER : "");
    setPropertyId(selectedClient ? selectedClient.PROJECTID : "");
    setPropertyName(selectedClient ? selectedClient.PROJECTNAME : "");
    axios
      .post(
        `${BASEURL}/getclentunitidandpropid`,
        {
          CLIENTCODE: selectedClient.CLIENTCODE,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        if (response?.data?.length === 0) {
          alert("Lease number don't exist");
          window.location.reload();
        } else {
          // setleasenumber(response.data[0]);
          // console.log(first)
          // setleasenumber(response.data[0]?.LEASENUMBER);
          // setsubUnitNumber(response.data[0]?.SUBUNITNO);
          //  setPropertyId(response.data[0]?.PROJECTID);
          //   setPropertyName(response.data[0]?.PROJECTNAME);
          console.log(response.data);
          console.log(response.data[0]?.PROJECTID);
          console.log(response.data[0]?.PROJECTNAME);
          console.log(response.data[0]?.LEASENUMBER);
        }
      })
      .catch((err) => {
        alert("Server error");
      });
  };

  function inspectionarea() {
    axios
      .get(`${BASEURL}/getinspectionlist`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setInspectionArea(response?.data);
      });
  }
  useEffect(() => {
    inspectionarea();
  }, []);

  if (isLoading) {
    return <Loading />;
  }
  function reset() {
    window.location.reload();
  }

  const DropDownlist = [
    "NC",
    "NP",
    "NR",
    "SC",
    "NSC",
    "NSP",
    "RP",
    "GC",
    "OTHER",
  ];
  // const handleMoveInChange = (index, selectedOptions) => {
  //   // setdropdownvaluesformvein(selectedOptions);
  //   setSelected(selectedOptions);
  //   console.log(selectedOptions);

  //   const updatedDetails = [...Moveinoption];
  //   updatedDetails[index] = Array.isArray(selectedOptions)
  //     ? selectedOptions.map((option) => option.value).join(",")
  //     : "";

  //   // Remove the initial comma if present
  //   updatedDetails[index] = updatedDetails[index].replace(/^,/, "");

  //   setMoveinoption(updatedDetails);
  //   console.log(updatedDetails);
  // };
  const handleMoveInChange = (index, selectedOptions) => {
    const updatedDetails = [...Moveinoption];

    if (selectedOptions.some((option) => option.value === "OTHER")) {
      // If 'OTHER' is selected, replace with an input field
      updatedDetails[index] = "OTHER";
    } else {
      // If other options are selected, join them with commas
      updatedDetails[index] = Array.isArray(selectedOptions)
        ? selectedOptions.map((option) => option.value).join(",")
        : "";
    }

    setMoveinoption(updatedDetails);
    console.log(updatedDetails);
  };

  const handleMoveInInputChange = (index, inputValue) => {
    const updatedDetails = [...Moveinoption];
    updatedDetails[index] = "OTHER";
    updatedDetails[index + "Input"] = inputValue;
    setMoveinoption(updatedDetails);
    console.log(updatedDetails);
  };
  const handleNarrationChange = (index, event) => {
    const updatedDetails = [...narrationDetails];
    updatedDetails[index] = event.target.value;
    setNarrationDetails(updatedDetails);
    console.log(updatedDetails);
  };

  console.log(moveInDetails);

  console.log(narrationDetails);

  const filteredData = InspectionDetailstomap.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);

    if (searchData.length === 0) {
      return true;
    }

    const textMatch = searchData.every((term) => {
      return [
        "INSPECTIONNO",
        "INSPECTIONITEM",
        "INSPECTIONAREA",
        "INSPECTIONSTATUS",
        "	ITEMNARRATION",
      ].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    return textMatch;
  });
  const openInNewWindow = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  return (
    <div>
      <div className="elctric__page">
        <MenuNavbar />
        <div className="center-container_1">
          <div className="form-container_1  ">
            <main className="form_info ">
              <header className="header__of__main flex justify-between mr-5">
                <h1 className="header__of__page">
                  {menu ? menu : "Inspection Details"}
                </h1>

                <div className=" flex gap-5 m-2">
                  <button
                    className="button-50"
                    onClick={() => {
                      Save();
                    }}
                  >
                    Save
                  </button>
                  <button
                    className="button-50 "
                    onClick={() => {
                      reset();
                    }}
                  >
                    New Record
                  </button>
                  <button
                    className="button-50 "
                    onClick={() => {
                      navigate("/PENDINGEXITINSPECTIONDETAILS");
                    }}
                  >
                    EXIT/EDIT INSPECTIONS
                  </button>
                </div>
              </header>
              <section className="section__newgarage ">
                <aside className="aside__new__garage UnitTypeMaster__width">
                  <div>
                    <p style={{ width: "200px" }}>
                      <label className="garage_id_ ">INSPECTION TYPE</label>
                    </p>
                    <div>{HeaderType}</div>
                  </div>
                  <div>
                    <p style={{ width: "200px" }}>
                      <label className="garage_id_ ">INSPECTION NO</label>
                    </p>
                    <div className=" text-red-600 font-bold">
                      {" "}
                      {EditableInspctionNo
                        ? EditableInspctionNo
                        : InspectionNo.NEXTNO}
                    </div>
                  </div>
                </aside>
                <aside className="aside__new__garage UnitTypeMaster__width">
                  <div>
                    <p style={{ width: "200px" }}>
                      <label className="garage_id_ ">INSPECTION CLIENT </label>
                    </p>
                    <div className="  w-[100%]" style={{ zIndex: 500 }}>
                      <Select
                        value={{
                          value: selectedClientName
                            ? selectedClientName
                            : clientCode,
                          label: selectedClientName
                            ? selectedClientName
                            : clientCode,
                        }}
                        className=" "
                        options={clientName.map((item) => ({
                          value: item["CLIENTNAME"],
                          label: item["CLIENTNAME"],
                        }))}
                        onChange={handleClientNameChange}
                      />
                    </div>
                  </div>
                  <div>
                    <p style={{ width: "200px" }}>
                      <label className="garage_id_ ">INSPECTION DATE </label>
                    </p>
                    <div className=" text-red-600 font-bold">
                      {editInspectionDate
                        ? moment(editInspectionDate).format("DD/MM/YYYY")
                        : moment(dateValue).format("DD/MM/YYYY")}
                    </div>
                  </div>
                </aside>
                <aside className="aside__new__garage UnitTypeMaster__width">
                  {/* <div>
                    <p style={{ width: "200px" }}>
                      <label className="garage_id_ ">
                        INSPECTION CARRIED <br /> OUT BY{" "}
                      </label>
                    </p>
                    <div style={{ zIndex: 300 }}>
                      <Select
                        value={{
                          value: CarriedByvalue,
                          label: CarriedByvalue,
                        }}
                        onChange={(e) => setCarriedByValue(e.value)}
                        className="ml-4 "
                        options={Carriedoutby.map((item) => ({
                          value: item["EMPLOYEE NAME"],
                          label: item["EMPLOYEE NAME"],
                        }))}
                      />
                    </div>
                    
                  </div> */}
                </aside>
                <aside className="aside__new__garage UnitTypeMaster__width">
                  <div>
                    <p style={{ width: "200px" }}>
                      <label className="garage_id_ ">PROPERTY NAME</label>
                    </p>
                    <div>
                      <input className="  font-bold" value={PropertyName} />
                      {/* {Inspectionleaseno} */}
                    </div>
                    {/* {Carriedoutby["EMPLOYEE NAME"]} */}
                  </div>

                  <div>
                    <p style={{ width: "350px" }}>
                      <label className="garage_id_ ">SUB UNIT NUMBER</label>
                    </p>
                    <div>
                      <input className="  font-bold" value={subUnitNumber} />
                      {/* {Inspectionleaseno} */}
                    </div>
                  </div>
                </aside>
                <aside className="aside__new__garage UnitTypeMaster__width">
                  <div>
                    <p style={{ width: "200px" }}>
                      <label className="garage_id_ ">
                        INSPECTION CARRIED <br />
                        OUT BY{" "}
                      </label>
                    </p>
                    <div style={{ zIndex: 400 }}>
                      <Select
                        value={{
                          value: CarriedByvalue,
                          label: CarriedByvalue,
                        }}
                        onChange={(e) => setCarriedByValue(e.value)}
                        className=""
                        options={Carriedoutby.map((item) => ({
                          value: item["EMPLOYEE NAME"],
                          label: item["EMPLOYEE NAME"],
                        }))}
                      />
                    </div>
                    {/* {Carriedoutby["EMPLOYEE NAME"]} */}
                  </div>
                  <div>
                    <p style={{ width: "350px" }}>
                      <label className="garage_id_ ">METER READING</label>
                    </p>
                    <div>
                      <input
                        type="number"
                        className="font-bold"
                        value={Meterreading}
                        onChange={(e) => setMeterReading(e.target.value)}
                      />
                      {/* {Inspectionleaseno} */}
                    </div>
                  </div>
                </aside>
                <aside className="aside__new__garage UnitTypeMaster__width">
                  <div>
                    <p style={{ width: "350px" }}>
                      <label className="garage_id_ ">
                        INSPECTION NARRATION
                      </label>
                    </p>
                    <textarea
                      value={narration}
                      onChange={(e) => setNarration(e.target.value)}
                      class="textmax__width border-solid border-black border-[1px] min-w-[300px] max-w-[300px] min-h-[80px] max-h-[80px]"
                    ></textarea>
                  </div>
                  <div>
                    <p style={{ width: "350px" }}>
                      <label className="garage_id_ ">INSPECTION DOCUMENT</label>
                    </p>
                    <div>
                      <input
                        type="file"
                        className="font-bold"
                        onChange={handleFileChange} // Handle file selection
                      />
                    </div>
                  </div>
                </aside>
                <aside className="aside__new__garage UnitTypeMaster__width">
                  {" "}
                  <div>
                    <p style={{ width: "350px" }}>
                      <label className="garage_id_ ">
                        DOCUMENT ATTACHED NARRATION
                      </label>
                    </p>
                    <textarea
                      value={inspectionDocumentNarration}
                      onChange={(e) =>
                        setInspectionDocumentNarration(e.target.value)
                      }
                      class="textmax__width border-solid border-black border-[1px] min-w-[300px] max-w-[300px] min-h-[80px] max-h-[80px]"
                    ></textarea>
                  </div>
                  {imagearray && imagearray.length > 0 && (
                    <div className="mt-4">
                      <p className="font-bold">Preview:</p>
                      {imagearray.map((document, index) => (
                        <div key={index} className="mb-4  gap-4">
                          <iframe
                            src={document.DOCUMENTATTACHED}
                            style={{
                              width: "100%",
                              height: "400px",
                              display: "block",
                              border: "none",
                              borderRadius: "8px",
                              cursor: "pointer",
                            }}
                            title={`File Preview ${index + 1}`}
                          />
                          <button
                            onClick={() =>
                              openInNewWindow(document.DOCUMENTATTACHED)
                            }
                          >
                            View
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                </aside>
              </section>
            </main>
          </div>
        </div>
        <div style={{ margin: "20px" }}>
          {" "}
          <aside className="flex items-center gap-4 m-3">
            <p>Filter By Inspection Area :</p>
            <div className="w-[300px]" style={{ zIndex: 200 }}>
              <Select
                options={uniqueInspectionAreas.map((item) => ({
                  value: item,
                  label: item,
                }))}
                onChange={handleSelectChange}
                value={{ value: selectedValue, label: selectedValue }}
              ></Select>
            </div>
          </aside>
          <main className="main__style__home">
            <section className="section__newgarage__">
              <aside>
                <div className="details__capture">
                  <div className="garage_id_label">
                    <p>NC:</p>
                    <p> NEEDS CLEANING</p>
                  </div>

                  <div className="garage_id_label">
                    <p>NP: </p>
                    <p> NEEDS PAINTING</p>
                  </div>
                  <div className="garage_id_label">
                    <p>NR: </p>
                    <p> NEEDS REPAIR</p>
                  </div>
                  <div className="garage_id_label">
                    <p>SC: </p>
                    <p> SCRATCHED</p>
                  </div>
                </div>
                <div className="details__capture">
                  <div className="garage_id_label">
                    <p>NSC: </p>
                    <p> NEEDS SPOT CLEANING</p>
                  </div>
                  <div className="garage_id_label">
                    <p>NSP: </p>
                    <p> NEEDS SPOT PAINTING</p>
                  </div>
                  <div className="garage_id_label">
                    <p>RP: </p>
                    <p> NEEDS REPLACING</p>
                  </div>
                  <div className="garage_id_label">
                    <p>GC: </p>
                    <p>GOOD CONDITION</p>
                  </div>
                  <div className="garage_id_label">
                    <p style={{ width: "200px" }}>OTHER </p>
                    <p></p>
                  </div>
                </div>
              </aside>
            </section>
            <div className="service_detials__">
              <table className="table_1">
                <thead style={{ zIndex: 40 }}>
                  <tr>
                    <td>INSPECTION&nbsp;AREA</td>

                    <td>INSPECTION&nbsp;ITEMS</td>
                    <td>MOVE&nbsp;-&nbsp;IN</td>
                    {/* <td>MOVE-OUT</td> */}
                    <td>INSPECTION&nbsp;ITEM&nbsp;NARRATION</td>
                  </tr>
                </thead>
                <tbody>
                  {InspectItems?.map((item, index) => (
                    <tr key={index}>
                      <td style={{ padding: "10px" }}>{item.INSPECTIONAREA}</td>
                      <td style={{ padding: "10px" }}>{item.INSPECTIONITEM}</td>
                      <td style={{ padding: "10px" }}>
                        <div style={{ zIndex: 500 }}>
                          <div style={{ width: "300px", zIndex: 500 }}>
                            {/* <MultiSelect
                              options={DropDownlist.map((option) => ({
                                label: option,
                                value: option,
                              }))}
                              value={(Moveinoption[index]
                                ? Moveinoption[index].split(",")
                                : []
                              ).map((value) => ({
                                label: value,
                                value,
                              }))}
                              onChange={(selectedOptions) =>
                                handleMoveInChange(index, selectedOptions)
                              }
                              labelledBy={"Select"}
                            /> */}

                            {Moveinoption[index] === "OTHER" ? (
                              <input
                                placeholder="Enter the Status"
                                type="text"
                                style={{
                                  width: "240px",
                                  minHeight: "30px",
                                  fontSize: "16px",
                                }}
                                value={Moveinoption[index + "Input"]}
                                onChange={(e) =>
                                  handleMoveInInputChange(index, e.target.value)
                                }
                              />
                            ) : (
                              <MultiSelect
                                options={DropDownlist.map((option) => ({
                                  label: option,
                                  value: option,
                                }))}
                                value={(Moveinoption[index]
                                  ? Moveinoption[index].split(",")
                                  : []
                                ).map((value) => ({
                                  label: value,
                                  value,
                                }))}
                                onChange={(selectedOptions) =>
                                  handleMoveInChange(index, selectedOptions)
                                }
                                labelledBy={"Select"}
                                hasSelectAll={false}
                              />
                            )}
                          </div>
                        </div>
                      </td>
                      <td style={{ padding: "10px" }}>
                        <textarea
                          className="textmax__width border-solid border-black border-[1px] min-w-[200px] max-w-[200px] min-h-[50px] max-h-[50px]"
                          onChange={(event) =>
                            handleNarrationChange(index, event)
                          }
                        >
                          {item.ITEMNARRATION ? item.ITEMNARRATION : ""}
                        </textarea>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </main>
        </div>
      </div>

      <div>
        <div className="searchhhhhh">
          <p>Search:</p>
          <input
            className="input_1"
            required
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          ></input>
          <div className="search__icon">
            {" "}
            <FaSearch />
          </div>
        </div>
        <div className="service_detials">
          <table className="table_1">
            <thead>
              <tr>
                <td style={{ textAlign: "right", width: "150px" }}>
                  INSPECTION NO
                </td>
                <td>INSPECTION ITEM</td>
                <td>INSPECTION AREA</td>
                <td>INSPECTION STATUS</td>
                <td>ITEM NARRATION</td>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((item) => (
                <tr>
                  <td style={{ textAlign: "right", width: "150px" }}>
                    {item.INSPECTIONNO ? item.INSPECTIONNO : "N/A"}
                  </td>
                  <td>{item.INSPECTIONITEM ? item.INSPECTIONITEM : "N/A"}</td>
                  <td>{item.INSPECTIONAREA ? item.INSPECTIONAREA : "N/A"}</td>
                  <td>
                    {item.INSPECTIONSTATUS ? item.INSPECTIONSTATUS : "N/A"}
                  </td>
                  <td>{item.ITEMNARRATION ? item.ITEMNARRATION : "N/A"}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default InspectionDetails;
